export const environment = {
  firebase: {
    projectId: 'mashcanta-e1446',
    appId: '1:219384944165:web:926b03ad613c0447a551cc',
    storageBucket: 'mashcanta-e1446.appspot.com',
    apiKey: 'AIzaSyBfm7dFfe0lOlLN1dc0X7u5Enkb__NDV5c',
    authDomain: 'mashcanta-e1446.firebaseapp.com',
    messagingSenderId: '219384944165',
    measurementId: 'G-4D83KK30YW',
  },
  production: true
};
