<nav class="navbar navbar-expand-lg navbar-light sticky-top">
    <a class="navbar-brand" routerLink="/home-two"><img src="assets/img/logo.png" alt="Logo"></a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
            <li class="nav-item"><a class="nav-link" href="#home">Home</a></li>

            <li class="nav-item"><a class="nav-link" href="#about">About Us</a></li>

            <li class="nav-item"><a class="nav-link" href="#services">Services</a></li>

            <li class="nav-item"><a class="nav-link" href="#team">Team</a></li>

            <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li>

            <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
        </ul>
    </div>

    <div class="side-nav">
        <a class="side-nav-left" routerLink="/">Sign In</a>
        <a class="side-nav-right cd-signup" routerLink="/"><i class='bx bx-link-external'></i>Sign Up</a>
    </div>
</nav>

<div class="two-banner-wrap">
    <div class="two-banner-slider owl-theme owl-carousel">
        <div class="two-banner-area two-banner-one">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="banner-content">
                            <span>
                                <img src="assets/img/home-two/banner-icon.png" alt="Banner">
                                Welcome To Levi
                            </span>
                            <h1>Levi Residential Mortgage</h1>
                            <div class="banner-btn">
                                <a class="cmn-banner-btn" routerLink="/"><i class='bx bx-note'></i> Apply Now</a>
                                <a class="banner-right-btn" routerLink="/"><i class='bx bx-calculator'></i> Calculator</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="two-banner-area two-banner-two">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="banner-content">
                            <span>
                                <img src="assets/img/home-two/banner-icon.png" alt="Banner">
                                Welcome To Levi
                            </span>
                            <h1>Buy A Home By Confidence</h1>
                            <div class="banner-btn">
                                <a class="cmn-banner-btn" routerLink="/"><i class='bx bx-note'></i> Apply Now</a>
                                <a class="banner-right-btn" routerLink="/"><i class='bx bx-calculator'></i> Calculator</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="two-banner-area two-banner-three">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="banner-content">
                            <span>
                                <img src="assets/img/home-two/banner-icon.png" alt="Banner">
                                Welcome To Levi
                            </span>
                            <h1>Mortgage Is A Great For You</h1>
                            <div class="banner-btn">
                                <a class="cmn-banner-btn" routerLink="/"><i class='bx bx-note'></i> Apply Now</a>
                                <a class="banner-right-btn" routerLink="/"><i class='bx bx-calculator'></i> Calculator</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 p-0">
                <div class="banner-time-left">
                    <ul>
                        <li>
                            <i class='bx bx-globe'></i>
                            <span>29-14 Entin inter</span>
                            <p>New York 11105</p>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <span>(24 hours / 7 days)</span>
                            <a href="tel:34212213259">3421 221 3259</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 p-0">
                <div class="banner-time-right">
                    <ul>
                        <li>
                            <i class='bx bx-timer'></i>
                            <span>Mon-Fri (8am - 6pm)</span>
                            <p>Sat-Sun (10am - 2pm)</p>
                        </li>
                        <li>
                            <i class='bx bxs-contact'></i>
                            <span>Contact us </span>
                            <a href="mailto:support@levi.com">support@levi.com</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="about" class="two-mission-area pt-100">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">GET INTRODUCED</span>
            <h2>Our Mission Is To Become The Best Mortgage</h2>
        </div>
        
        <div class="mission-content">
            <img src="assets/img/home-two/mission.jpg" alt="Mission">

            <div class="mission-bottom">
                <ul class="align-items-center">
                    <li>
                        <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube">
                            <i class='bx bx-play' ></i>
                        </a>
                        <p>Intro With Compnay</p>
                    </li>
                    <li>
                        <div class="mission-item">
                            <i class='bx bxs-user'></i>
                            <span>Speak With Loan Officer</span>
                        </div>
                    </li>
                    <li>
                        <div class="mission-item">
                            <i class='bx bx-certification'></i>
                            <span>Tools Resource</span>
                        </div>
                    </li>
                    <li>
                        <div class="mission-item">
                            <i class='bx bxs-hand-right'></i>
                            <span>Get Started With Levi</span>
                        </div>
                    </li>
                    <li>
                        <div class="mission-item">
                            <i class='bx bxs-home-circle'></i>
                            <span>See Home Pricing</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="one-counter-area two-counter-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <h3>20</h3>
                    <p>YEARS OF EXPERIENCE</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <h3>98</h3>
                    <p>VOLUNTEERS PARTICIPATED</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <h3>56</h3>
                    <p>VETERAN HOMES COMPLETED</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <h3>90</h3>
                    <p>CUSTOMER WORLDWIDE</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="one-among-area two-among-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">WHY CHOOSE US</span>
            <h2>We Have Offer To You</h2>
            <p>Choose started sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore adipiscing elit sed do eiusr adipiscing elitatur  liqua  </p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="assets/img/home-two/among1.png" alt="Among">
                        <div class="among-icon">
                            <i class='bx bxs-badge-dollar'></i>
                        </div>
                    </div>

                    <div class="among-bottom">
                        <h3>Delivering A Great Customer Experience</h3>
                        <p>Gain home equity by paying sit amet seeturlit sed dolor se equity </p>
                        <a routerLink="/">READ MORE <i class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="assets/img/home-two/among2.png" alt="Among">
                        <div class="among-icon">
                            <i class='bx bx-bulb'></i>
                        </div>
                    </div>

                    <div class="among-bottom">
                        <h3>Refinancing Your Home No Doubt About Anything</h3>
                        <p>Your home equity by paying sit amet seeturlit sed dolor se equity </p>
                        <a routerLink="/">READ MORE <i class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="assets/img/home-two/among3.png" alt="Among">
                        <div class="among-icon">
                            <i class='bx bx-money'></i>
                        </div>
                    </div>

                    <div class="among-bottom">
                        <h3>Have A Superior Power To Unlock The Today’s Rate</h3>
                        <p>Money rate equity by paying sit amet seeturlit sed dolor se equity </p>
                        <a routerLink="/">READ MORE <i class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="assets/img/home-two/among4.png" alt="Among">
                        <div class="among-icon">
                            <i class='bx bx-user'></i>
                        </div>
                    </div>

                    <div class="among-bottom">
                        <h3>Without A Doubt To Have A Apply For Loan Offer</h3>
                        <p>Inquri home equity by paying sit amet seeturlit sed dolor se equity </p>
                        <a routerLink="/">READ MORE <i class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="assets/img/home-two/among5.png" alt="Among">
                        <div class="among-icon">
                            <i class='bx bx-message-alt-detail'></i>
                        </div>
                    </div>

                    <div class="among-bottom">
                        <h3>Consideration Have Been Divided Get Refinance Advisor</h3>
                        <p>Divided home equity by paying sit amet seeturlit sed dolor se equity </p>
                        <a routerLink="/">READ MORE <i class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="assets/img/home-two/among6.png" alt="Among">
                        <div class="among-icon">
                            <i class='bx bx-home'></i>
                        </div>
                    </div>

                    <div class="among-bottom">
                        <h3>A Ethical Solution For Future Buying & Selling Home</h3>
                        <p>Fuuture home equity by paying sit amet seeturlit sed dolor se equity </p>
                        <a routerLink="/">READ MORE <i class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="two-house-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="house-item">
                    <div class="house-icon">
                        <i class='bx bx-dollar-circle'></i>
                    </div>
                    <h3>Mortgage Pre-Approval</h3>
                    <p>You can grow in your new homeit amet seeturlit sed ew homeew homedolor</p>
                    <a routerLink="/">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="house-item">
                    <div class="house-icon">
                        <i class='bx bx-shopping-bag'></i>
                    </div>
                    <h3>House Shopping</h3>
                    <p>You can grow in your new homeit amet seeturlit sed ew homeew homedolor</p>
                    <a routerLink="/">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="house-item">
                    <div class="house-icon">
                        <i class='bx bx-note'></i>
                    </div>
                    <h3>Loan Application</h3>
                    <p>You can grow in your new homeit amet seeturlit sed ew homeew homedolor </p>
                    <a routerLink="/">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<section id="services" class="one-service-area two-service-area ptb-100">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">WHAT’S THE BEST</span>
            <h2>Mortgage Programs</h2>
            <p>Experinces started sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore adipiscing elit sed do eiusr adipiscing elitatur  liqua</p>
        </div>

        <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <a class="nav-item nav-default active" id="nav-home-tab" data-bs-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">
                    <div class="service-item">
                        <i class='bx bxs-building-house'></i>
                        <span>Purchase New House</span>
                    </div>
                </a>

                <a class="nav-item nav-default" id="nav-profile-tab" data-bs-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">
                    <div class="service-item">
                        <i class='bx bxs-dollar-circle'></i>
                        <span>Apply For Loan</span>
                    </div>
                </a>
                
                <a class="nav-item nav-default" id="nav-contact-tab" data-bs-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">
                    <div class="service-item">
                        <i class='bx bxs-home-circle'></i>
                        <span>Refinancing Your Home</span>
                    </div>
                </a>

                <a class="nav-item nav-default" id="nav-x-tab" data-bs-toggle="tab" href="#nav-x" role="tab" aria-controls="nav-x" aria-selected="false">
                    <div class="service-item">
                        <i class='bx bxs-hand-up'></i>
                        <span>Capital Management</span>
                    </div>
                </a>

                <a class="nav-item nav-default" id="nav-y-tab" data-bs-toggle="tab" href="#nav-y" role="tab" aria-controls="nav-y" aria-selected="false">
                    <div class="service-item">
                        <i class='bx bxs-wrench'></i>
                        <span>Renovation House</span>
                    </div>
                </a>

                <a class="nav-item nav-default" id="nav-z-tab" data-bs-toggle="tab" href="#nav-z" role="tab" aria-controls="nav-z" aria-selected="false">
                    <div class="service-item">
                        <i class='bx bxs-user-circle'></i>
                        <span>Refinance Advisor</span>
                    </div>
                </a>
            </div>
        </nav>
            
        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="assets/img/home-one/advisor.jpg" alt="Advisor">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Purchase New House</h2>
                                <p>Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit</p>
                                <ul>
                                    <li><i class='bx bxs-chevrons-right'></i> Respect for all people</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Excellence in everything we do</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Truthfulness in our business</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Unquestionable integrity</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="assets/img/home-one/advisor2.jpg" alt="Advisor">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Apply For Loan</h2>
                                <p>Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit</p>
                                <ul>
                                    <li><i class='bx bxs-chevrons-right'></i> Respect for all people</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Excellence in everything we do</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Truthfulness in our business</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Unquestionable integrity</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="assets/img/home-one/advisor3.jpg" alt="Advisor">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Refinancing Your Home</h2>
                                <p>Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit</p>
                                <ul>
                                    <li><i class='bx bxs-chevrons-right'></i> Respect for all people</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Excellence in everything we do</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Truthfulness in our business</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Unquestionable integrity</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-x" role="tabpanel" aria-labelledby="nav-x-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="assets/img/home-one/advisor4.jpg" alt="Advisor">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Capital Management</h2>
                                <p>Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit</p>
                                <ul>
                                    <li><i class='bx bxs-chevrons-right'></i> Respect for all people</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Excellence in everything we do</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Truthfulness in our business</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Unquestionable integrity</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-y" role="tabpanel" aria-labelledby="nav-y-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="assets/img/home-one/advisor5.jpg" alt="Advisor">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Renovation House</h2>
                                <p>Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit</p>
                                <ul>
                                    <li><i class='bx bxs-chevrons-right'></i> Respect for all people</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Excellence in everything we do</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Truthfulness in our business</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Unquestionable integrity</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-z" role="tabpanel" aria-labelledby="nav-z-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="assets/img/home-one/advisor6.jpg" alt="Advisor">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Refinance Advisor</h2>
                                <p>Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit</p>
                                <ul>
                                    <li><i class='bx bxs-chevrons-right'></i> Respect for all people</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Excellence in everything we do</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Truthfulness in our business</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Unquestionable integrity</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="two-address-area pt-100">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">GET ADDRESS</span>
            <h2>Here Is Our Roadmap</h2>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="address-item">
                    <img src="assets/img/home-two/address1.jpg" alt="Address">
                    <ul>
                        <li><a target="_blank" href="#"><i class='bx bxs-home'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bx-street-view'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxs-graduation'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxs-folder-open'></i></a></li>
                    </ul>
                    <h3>You’re In Right Place!</h3>
                    <p>Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="address-item">
                    <img src="assets/img/home-two/address2.jpg" alt="Address">
                    <ul>
                        <li><a target="_blank" href="#"><i class='bx bxs-home'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bx-street-view'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxs-graduation'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxs-folder-open'></i></a></li>
                    </ul>
                    <h3>You Can See The Map</h3>
                    <p>Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit</p>
                </div>
            </div>
        </div>
    </div>

    <div class="one-quote-area">
        <div class="container">
            <div class="row m-0">
                <div class="col-lg-6 p-0">
                    <div class="quote-content">
                        <h3>Get Mortgage Quote</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing eltsed do eiusmod tempor incididunt ut labore et dolore </p>
                        <a routerLink="/"><i class='bx bxs-quote-alt-right'></i>Get A Quote</a>
                    </div>
                </div>

                <div class="col-lg-6 p-0">
                    <div class="quote-img">
                        <img src="assets/img/home-one/quote.jpg" alt="Quote">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="team" class="one-team-area two-team-area pb-70">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">OUR TEAM</span>
            <h2>Our Staff Profiles</h2>
            <a routerLink="/"><i class='bx bx-plus'></i> JOIN TEAM</a>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/home-one/team1.png" alt="Team">
                    </div>
                    <ul>
                        <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                    <h3>David Driscoll</h3>
                    <span>Manager</span>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/home-one/team2.png" alt="Team">
                    </div>
                    <ul>
                        <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                    <h3>Andon wardin</h3>
                    <span>Loan Officer</span>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/home-one/team3.png" alt="Team">
                    </div>
                    <ul>
                        <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                    <h3>Mark morrell</h3>
                    <span>Area Manager</span>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <div class="team-img">
                        <img src="assets/img/home-one/team4.png" alt="Team">
                    </div>
                    <ul>
                        <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                    <h3>JUBIN INDONA</h3>
                    <span>Accountant</span>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="one-help-area two-help-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-7 p-0">
                <div class="help-item">
                    <div class="one-section-title">
                        <span class="sub-title">FUN FACT</span>
                        <h2>There Is Some Fact That You Know</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <ul>
                        <li>
                            <div class="help-icon">
                                <i class='bx bx-brain'></i>
                            </div>
                            <div class="help-inner">
                                <h3>Loan Officer Assistant do?</h3>
                                <p>When you own your own homet seeturlit sed dolor sit am</p>
                            </div>
                        </li>
                        <li>
                            <div class="help-icon">
                                <i class='bx bx-home-alt'></i>
                            </div>
                            <div class="help-inner">
                                <h3>RMS is Working Harder </h3>
                                <p>You can grow in your new homeit amet seeturlit sed dolor sit </p>
                            </div>
                        </li>
                    </ul>

                    <div class="advisor-link">
                        <a routerLink="/">KNOWLEDGE CENTER</a><i class="bx bx-right-arrow-alt"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 p-0">
                <div class="help-img-two">
                    <img src="assets/img/home-two/fun.jpg" alt="Fun">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="two-lover-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">COUSTOMER FEEDBACKS</span>
            <h2>What People Say</h2>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="lover-item">
                    <ul class="align-items-center">
                        <li>
                            <div class="lover-img">
                                <img src="assets/img/home-two/lover1.png" alt="Lover">
                                <i class='bx bxs-quote-alt-left'></i>
                            </div>
                        </li>
                        <li>
                            <div class="lover-content">
                                <h3>Donney Jon</h3>
                                <span>Perisian Org</span>
                                <p>I had ipsum dolor sit a consetusicing elit sed do eiusmod mpor nnim ad mini eiusmod nnim </p>
                                <ul>
                                    <li><i class='bx bxs-star checked'></i></li>
                                    <li><i class='bx bxs-star checked'></i></li>
                                    <li><i class='bx bxs-star checked'></i></li>
                                    <li><i class='bx bxs-star checked'></i></li>
                                    <li><i class='bx bxs-star checked'></i></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-6">
                <div class="lover-item">
                    <ul class="align-items-center">
                        <li>
                            <div class="lover-img">
                                <img src="assets/img/home-two/lover2.png" alt="Lover">
                                <i class='bx bxs-quote-alt-left'></i>
                            </div>
                        </li>
                        <li>
                            <div class="lover-content">
                                <h3>Oli Rubion</h3>
                                <span>Entoin Ect</span>
                                <p>Realy ipsum dolor sit a consetusicing elit sed do eiusmod mpor nnim ad mini eiusmod nnim </p>
                                <ul>
                                    <li><i class='bx bxs-star checked'></i></li>
                                    <li><i class='bx bxs-star checked'></i></li>
                                    <li><i class='bx bxs-star checked'></i></li>
                                    <li><i class='bx bxs-star checked'></i></li>
                                    <li><i class='bx bxs-star checked'></i></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="one-faq-area two-faq-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="faq-img-two">
                    <img src="assets/img/home-two/faq.jpg" alt="FAQ">
                </div>
            </div>

            <div class="col-lg-7">
                <div class="faq-content">
                    <ul class="accordion">
                        <li>
                            <a>How do you qualify for a loan?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                        <li>
                            <a>A Good Lender Will Clearly Explain Mortgage?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                        <li>
                            <a>Can You Get A Mortgage Without A Credit Score?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                        <li>
                            <a>How Much Home Can You Afford?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="contact" class="one-contact-area two-contact-area ptb-100">
    <div class="container">
        <div class="contact-content">
            <div class="one-section-title">
                <span class="sub-title">LET’S CONTACT</span>
                <h2>Contact With Us</h2>
            </div>

            <form id="contactForm">
                <div class="row">
                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label><i class='bx bx-user'></i></label>
                            <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label><i class='bx bx-mail-send'></i></label>
                            <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label><i class='bx bx-phone-call'></i></label>
                            <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>
                                <i class='bx bxs-edit-alt'></i>
                            </label>
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject">
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label><i class='bx bx-message-square-detail'></i></label>
                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Message"></textarea>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="row m-0">
                            <div class="col-sm-4 col-lg-4 pl-0">
                                <button type="submit" class="contact-btn btn"><i class='bx bx-plus'></i> Send Message</button>
                            </div>

                            <div class="col-sm-8 col-lg-8">
                                <div class="contact-emergency">
                                    <i class='bx bx-phone-call'></i>
                                    <span>Emergency Contact</span>
                                    <a href="tel:+44554348554">+445 543 48554</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div id="blog" class="one-blog-area two-blog-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">NEWS & BLOG</span>
            <h2>Featured News And Insights</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/"><img src="assets/img/home-one/blog1.jpg" alt="Blog"></a>
                        <span>Mortgage</span>
                    </div>

                    <div class="blog-bottom">
                        <ul>
                            <li>
                                <i class='bx bx-time-five'></i>
                                09 April 2020
                            </li>
                            <li>
                                <i class='bx bx-comment-detail'></i>
                                0 Comment
                            </li>
                        </ul>
                        <h3><a routerLink="/">What Means Your Mortgage And 5 Ways To Improve</a></h3>
                        <img src="assets/img/home-one/blog1.png" alt="Blog">
                        <p><a routerLink="/">Zain jonson</a>, Author</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/"><img src="assets/img/home-one/blog2.jpg" alt="Blog"></a>
                        <span>Mortgage</span>
                    </div>

                    <div class="blog-bottom">
                        <ul>
                            <li>
                                <i class='bx bx-time-five'></i>
                                10 April 2020
                            </li>
                            <li>
                                <i class='bx bx-comment-detail'></i>
                                0 Comment
                            </li>
                        </ul>
                        <h3><a routerLink="/">8 Ways To Make Your Home A Smart Home</a></h3>
                        <img src="assets/img/home-one/blog2.png" alt="Blog">
                        <p><a routerLink="/">Movin Jex</a>, Author</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/"><img src="assets/img/home-one/blog3.jpg" alt="Blog"></a>
                        <span>Mortgage</span>
                    </div>

                    <div class="blog-bottom">
                        <ul>
                            <li>
                                <i class='bx bx-time-five'></i>
                                11 April 2020
                            </li>
                            <li>
                                <i class='bx bx-comment-detail'></i>
                                0 Comment
                            </li>
                        </ul>
                        <h3><a routerLink="/">5 Ways To build Yourself By Hard Working</a></h3>
                        <img src="assets/img/home-one/blog3.png" alt="Blog">
                        <p><a routerLink="/">Jems</a>, Author</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="one-subscribe-area two-subscribe-area ptb-100">
    <div class="container">
        <div class="subscribe-wrap">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <h2>Do you want to get update what’s upcoming?</h2>
                </div>
                <div class="col-lg-6">
                    <form class="newsletter-form" data-bs-toggle="validator">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
                        <button class="btn subscribe-btn" type="submit">Subscribe now</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="one-search-area ptb-100">
    <div class="container">
        <div class="search-wrap">
            <div class="search-content">
                <div class="one-section-title">
                    <span class="sub-title">SERCH YOUR ONE</span>
                    <h2>Find A Loan Officer</h2>
                </div>
                <form>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
                        <label class="form-check-label" for="exampleRadios1">
                            Real Estate Agents
                        </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
                        <label class="form-check-label" for="exampleRadios2">
                            Loan Officer
                        </label>
                    </div>
                    
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="First Name">
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Last Name">
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <select class="form-control">
                                    <option>License State</option>
                                    <option>Another option</option>
                                    <option>A option</option>
                                    <option>Potato</option>
                                </select>	
                            </div>
                        </div>
                    </div>

                    <div class="search-link">
                        <button type="submit">Search Your Result</button>
                    </div>
                </form>
            </div>

            <div class="search-img">
                <img src="assets/img/home-one/search-man.png" alt="Search">
            </div>
        </div>
    </div>
</div>

<footer class="one-footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo-two.png" alt="Logo"></a>
                        <p>Levi Financial Services, Inc. 200 Wood Avenue South, Ninth Floor Iselin, NJ 65432</p>
                        <p>Resouece Financial ServicInc. 200 Wood Avenue South</p>
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Quick Links</h3>

                        <ul>
                            <li><a routerLink="/">Mortgage Calculator</a></li>
                            <li><a routerLink="/">Mortgage Checklist</a></li>
                            <li><a routerLink="/">Mortgage Glossary</a></li>
                            <li><a routerLink="/">Renting vs Owning</a></li>
                            <li><a routerLink="/">Credit Basics</a></li>
                            <li><a routerLink="/">Disaster Relief Center</a></li>
                            <li><a routerLink="/">Disclosure</a></li>
                            <li><a routerLink="/">Home Disclosure Notice</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>About Us</h3>

                        <ul>
                            <li><a routerLink="/">Down Payment Options</a></li>
                            <li><a routerLink="/">Your Mortgage Payment</a></li>
                            <li><a routerLink="/">Appraisal Basics</a></li>
                            <li><a routerLink="/">Contact</a></li>
                            <li><a routerLink="/">Careers</a></li>
                            <li><a routerLink="/">Privacy Policy</a></li>
                            <li><a routerLink="/">Privacy Choices</a></li>
                            <li><a routerLink="/">Licencing</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-address">
                        <h3>Address</h3>

                        <ul>
                            <li>
                                <i class='bx bx-phone-call'></i>
                                <a href="tel:+4498886660000">+449 888 666 0000</a>
                            </li>
                            <li>
                                <i class='bx bx-phone-call'></i>
                                <a href="tel:+5498586560350">+549 858 656 0350</a>
                            </li>
                            <li>
                                <i class='bx bx-message-square-detail'></i>
                                <a href="mailto:hello@levi.com">hello@levi.com</a>
                            </li>
                            <li>
                                <i class='bx bx-message-square-detail'></i>
                                <a href="mailto:info@levi.com">info@levi.com</a>
                            </li>
                            <li>
                                <i class='bx bx-current-location' ></i>
                                855 road, broklyn street new york 600
                            </li>
                            <li>
                                <i class='bx bx-planet' ></i>
                                Levi is not acting behalf the Federal Government
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="copyright-item">
                        <ul>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/visa.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/master-card.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/paypal.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/skrill.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/master-card2.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/visa2.jpg" alt="Method"></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-item">
                        <p>Copyright @2021 Levi. All Right Reserved by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>